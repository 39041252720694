import { grey } from '@material-ui/core/colors'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import LoginBanner from '../../components/common/LoginBanner'
import AcceptInvitation from './AcceptInvitation'
import Form1SetPassword from './Form1_SetUserPassword'
import Form2CompanyDetails from './Form2_CompanyDetails'
import Form3CompanyAddress from './Form3_CompanyAddress'
import Success from './Success'

const Text = {
  Subtitle: styled.p`
    margin-top: 15px;
    /* Headers/H6 */

    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* White/100 */

    color: #ffffff;
  `,
  DontHaveAnAccount: styled.p`
    /* Body/Small/Semibold */

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143%;
    /* identical to box height, or 20px */

    text-align: center;
    letter-spacing: 0.018em;

    /* White/100 */

    color: #ffffff;
  `
}

const OuterContainer = styled.div`
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (min-width: 767px) {
    boder-radius:10px
  }
`

// markup
const IndexPage = (props) => {
  //   const { location: { state: { token = {} } = {} } = {} } = props
  //   const { userToken = '' } = token === null ? {} : token

  // get paras from url
  const queryParams = new URLSearchParams(window.location.search)
  const newUserToken = queryParams.get('token')
  const newUserEmail = queryParams.get('email')
  const isParticipant = queryParams.get('participant')
  const isMember = queryParams.get('member')
  const decodedEmail = decodeURIComponent(newUserEmail)
  const [totalPage] = React.useState(isMember ? 2 : 4)
  const { location: { state = {} } = {} } = props
  const { targetStep = '' } = state === null ? {} : state
  console.log(decodedEmail)
  // const userToken = props.location.state.token
  const userToken = newUserToken

  const [formOne, setFormOne] = React.useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneNumber: '',
    email: decodedEmail === 'null' ? '' : decodedEmail,
    language: 'English',
    password: '',
    confirmPassword: '',
    token: newUserToken === 'null' ? '' : newUserToken,
    isParticipant: isParticipant,
    isMember: isMember
  })
  const [formTwo, setFormTwo] = React.useState({
    companyName: '',
    companyRegiNum: '',
    companyTaxId: '',
    companyWebsite: '',
    companyDocument: '',
    companyDocumentFile: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    token: newUserToken === 'null' ? '' : newUserToken
  })
  // set step of forms
  const [step, setStep] = React.useState(targetStep || 1)

  // go to next step or the destination step
  const nextStep = (e) => {
    if (e) {
      setStep(e)
    } else {
      setStep(step + 1)
      console.log({ x: formOne.isMember, y: formOne.isParticipant })
    }
  }
  // go back to last step

  const prevStep = () => {
    setStep(step - 1)
  }

  const continueFill = (e) => {
    e.preventDefault()
    // TODO ASH: need to make sure that email is not empty
    nextStep()
  }
  // handle change of values Form one (personal forms)
  const handleChange = (input) => (e) => {
    setFormOne((ps) => ({ ...ps, [input]: e.target.value }))
  }

  // handle change of values in Form two ( company forms)
  const handleCompanyChange = (input) => (e) => {
    setFormTwo((ps) => ({ ...ps, [input]: e.target.value }))
  }
  const handleDocumentUpload = (input) => (e) => {
    setFormTwo((ps) => ({
      ...ps,
      companyDocumentFile: e.target.files[0],
      companyDocument: e.target.files[0].name
    }))
    console.log(e.target.files[0])
    console.log(formTwo.companyDocumentFile)
  }

  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return (
          <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5">
            {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
            <div className="d-flex justify-content-between">
              <h4 className="mb-3 font-weight-bold inter-font-content">Create your account </h4>
              <h4 className="mb-3 font-weight-bold inter-font-content">1/{totalPage}</h4>
            </div>
            <p>Provide the following details to create your account.</p>

            {/* form */}
            <Form onSubmit={(e) => continueFill(e)} className="d-flex flex-column flex-grow-1 justify-content-between">
              <div>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Email address"
                    autoComplete="given-name"
                    autoFocus={true}
                    value={formOne.firstName}
                    onChange={handleChange('firstName')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Email address"
                    autoComplete="family-name"
                    value={formOne.lastName}
                    onChange={(e) =>
                      setFormOne((ps) => ({ ...ps, lastName: e.target.value }))
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Email address"
                    autoComplete="organization-title"
                    value={formOne.jobTitle}
                    onChange={(e) =>
                      setFormOne((ps) => ({ ...ps, jobTitle: e.target.value }))
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Email address"
                    autoComplete="tel"
                    value={formOne.phoneNumber}
                    onChange={(e) =>
                      setFormOne((ps) => ({
                        ...ps,
                        phoneNumber: e.target.value
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contact email</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="email"
                    disabled={!!newUserEmail}
                    style={{ color: grey + '!important' }}
                    value={formOne.email}
                    required
                    onChange={(e) =>
                      setFormOne((ps) => ({
                        ...ps,
                        email: e.target.value
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    as="select"
                    value={formOne.language}
                    onChange={(e) =>
                      setFormOne((ps) => ({ ...ps, language: e.target.value }))
                    }
                  >
                    <option value="English"> English </option>
                    <option value="Chinese"> Chinese </option>
                  </Form.Control>
                </Form.Group>
              </div>

              <Form.Group className="mb-0 d-flex justify-content-between">
                <Button
                  variant="outline-primary"
                  type="button"
                  style={{ width: 100 + 'px' }}
                  onClick={() => window.history.back()}
                >
                  <span>Back</span>
                </Button>
                <Button
                  className="mt-0 "
                  type="submit"
                  // disabled={loginMutation.isLoading || credentials.username.length === 0 || credentials.password.length === 0}
                >
                  <span>Continue</span>
                </Button>
              </Form.Group>
            </Form>
          </div>
        )
      case 2:
        return (
          <Form1SetPassword
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formOne}
          />
        )
      case 3:
        return (
          <Form2CompanyDetails
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleCompanyChange}
            handleDocumentUpload={handleDocumentUpload}
            values={formTwo}
          />
        )
      case 4:
        return (
          <Form3CompanyAddress
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleCompanyChange}
            values={formTwo}
          />
        )
      case 5:
        return <Success title="Your application has been submitted" content=" The team have received your application and will begin the review process shortly."></Success>
      case 6:
        return <AcceptInvitation token={newUserToken} />
      default:
        return 'foo'
    }
  }
  return (
    <main className="main" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card  z-index-1" >
              <div className="card-body p-0" >
                <div className="row no-gutters h-100">
                  <LoginBanner />
                  <div className="col-md-7 d-flex flex-center" style={{ borderTopRightRadius: '0.35rem!important' }} >
                    {renderSwitch(step)}
                  </div>
                </div>
              </div>
            </OuterContainer>

          </div>
        </div>
      </div>
      {process.env.NODE_ENV !== 'production' && (
        <>
          <pre>credentials={JSON.stringify(formOne, null, 2)}</pre>
          <pre>credentials={JSON.stringify(formTwo, null, 2)}</pre>
        </>
      )}
    </main>
  )
}

export default IndexPage

import queryString from 'query-string'

/**
 * Performs a state merge of the key=>value pair, into the previous state.
 * @param {*} setter
 * @param {*} key
 * @param {*} value
 * @returns
 */
export const doChange = (setter, key, value) => {
  console.debug('doChange: setting value', { key, value, type: typeof value })
  return setter(ps => ({ ...ps, [key]: value }))
}

/**
 * Parse search params.
 * @param {*} props
 * @returns
 */
export const getQueryParams = (props = {}) => {
  const { location } = props
  const search = location.search ? queryString.parse(location.search) : {}
  return search
}

export const isUndef = o => typeof o === 'undefined'
export const isNull = o => o === null
export const isUndefOrNull = o => isUndef(o) || isNull(o)
export const isNonBlankString = s => typeof s === 'string' && s.trim().length > 0

export const quickAndDirtyCSV = array => {
  if (!Array.isArray(array)) {
    console.debug('quickAndDirtyCSV Error', array)
    throw new Error(`Expected an array, found=${array}`)
  }
  return array.map(value => `"${value}"`).join(',') + '\n'
}

/**
 * Generates a downloadable link.
 */
export const quickAndDirtyCSVDownloadLink = (headers, data) => {
  const header = quickAndDirtyCSV(headers)
  const body = data.map(row => quickAndDirtyCSV(row)).join('') + '\n'
  const csv = '\uFEFF' + header + body
  return ('data:text/csv;charset=utf-8,' + encodeURIComponent(csv))
}

export const NOOP = () => { console.error('NOOP invoked') }

export const download = (url) => window && window.open(url)

export const downloadDataString = (dataStr, filename) => {
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', filename)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

/**
 * Triggers an immediate download of the generated CSV data.
 * @param {string} filename
 * @param {string[]} headers
 * @param {string[][]} data
 */
export const triggerDownloadOfCSV = (filename, headers, data) => {
  const csvDataString = quickAndDirtyCSVDownloadLink(headers, data)
  downloadDataString(csvDataString, filename)
}

// function downloadObjectAsJson(exportObj, exportName) {
//   const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
//   const downloadAnchorNode = document.createElement('a')
//   downloadAnchorNode.setAttribute('href', dataStr)
//   downloadAnchorNode.setAttribute('download', exportName + '.json')
//   document.body.appendChild(downloadAnchorNode) // required for firefox
//   downloadAnchorNode.click()
//   downloadAnchorNode.remove()
// }
